import React from 'react';
import { Flex, Box } from 'reflexbox';

import { AppContext } from './AppContext';
import { ReachTable } from './ReachTable';
import { ReachData } from './ReachData';
import { SearchInput } from './SearchInput';
import './App.css';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';

export const App = () => {
  const [reachData, setReachData] = React.useState<ReachData[]>([]);

  const [searchInput, setSearchInput] = React.useState('');

  const [cellsLoading, setCellsLoading] = React.useState(true);

  const [shouldShowToast, setShouldShowToast] = React.useState(false);

  return (
    <Flex flexDirection="column" className="App" mt={10} flexWrap="wrap">
      <AppContext.Provider
        value={{
          cellsLoading,
          reachData,
          searchInput,
          setCellsLoading,
          setReachData,
          setSearchInput,
          setShouldShowToast,
          shouldShowToast,
        }}
      >
        <Box alignSelf="center" width={[1 / 2]} p={3}>
          <h1>Facebook Reach Lookup</h1>
        </Box>
        <Box alignSelf="center" width={[1 / 2]} p={3}>
          <SearchInput></SearchInput>
        </Box>
        <Box alignSelf="center" width={[3 / 4]} p={0}>
          <ReachTable></ReachTable>
        </Box>
      </AppContext.Provider>
    </Flex>
  );
};
