import React from 'react';
import { Column, Table, Cell, TableLoadingOption } from '@blueprintjs/table';
import { Toaster, Position, Intent } from '@blueprintjs/core';
import moment from 'moment';

import { AppContext } from './AppContext';

import '@blueprintjs/table/lib/css/table.css';

export class ReachTable extends React.Component {
  static contextType = AppContext;
  private tableInstance?: Table;
  private toaster?: Toaster;

  context!: React.ContextType<typeof AppContext>;

  private columns = [
    {
      mapToField: 'postCreatedTime',
      name: 'Date:',
      type: 'date',
    },
    {
      mapToField: 'socialCopy',
      name: 'Social Copy:',
      type: 'string',
    },
    {
      mapToField: 'shares',
      name: 'Shares:',
      type: 'number',
    },
    {
      mapToField: 'reach',
      name: 'Reach:',
      type: 'number',
    },
  ];

  private refHandlers = {
    table: (ref: Table) => (this.tableInstance = ref),
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  private handleResizeRowsByApproxHeight = () => {
    this.tableInstance?.resizeRowsByApproximateHeight(this.getCellText);
  };

  private getCellText = (rowIndex: number, columnIndex: number) => {
    return this.context.reachData[rowIndex][
      this.columns[columnIndex].mapToField
    ];
  };

  private defaultCellRenderer = (row: number, column: number) => {
    return (
      <Cell wrapText={true} interactive={true}>
        {this.context.reachData[row][this.columns[column].mapToField]}
      </Cell>
    );
  };

  private dateCellRenderer = (row: number, column: number) => {
    return (
      <Cell>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={this.context.reachData[row].permalinkUrl}
        >
          {moment(this.context.reachData[row].postCreatedTime).format(
            'MM-DD-YYYY, h:mm:ss a'
          )}
        </a>
      </Cell>
    );
  };

  componentDidUpdate() {
    if (this.context.shouldShowToast) {
      this.toaster?.show({
        intent: Intent.WARNING,
        message: 'No results!',
      });
      this.context.setShouldShowToast(false);
    }
    this.handleResizeRowsByApproxHeight();
  }

  render() {
    const { reachData, cellsLoading } = this.context;

    return (
      <div>
        <Toaster ref={this.refHandlers.toaster} position={Position.TOP} />
        <Table
          ref={this.refHandlers.table}
          getCellClipboardData={(row, column) =>
            reachData[row][this.columns[column].mapToField]
          }
          numRows={reachData.length}
          loadingOptions={(() =>
            cellsLoading ? [TableLoadingOption.CELLS] : [])()}
        >
          {this.columns.map(column => (
            <Column
              key={column.name}
              name={column.name}
              cellRenderer={
                column.type === 'date'
                  ? this.dateCellRenderer
                  : this.defaultCellRenderer
              }
            />
          ))}
        </Table>
      </div>
    );
  }
}
