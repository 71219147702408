import React from 'react';
import { ReachData } from './ReachData';

interface ReachContextType {
  cellsLoading: boolean;
  searchInput: string;
  reachData: ReachData[];
  setCellsLoading: (loading: boolean) => void;
  setReachData: (data: ReachData[]) => void;
  setSearchInput: (input: string) => void;
  setShouldShowToast: (flag: boolean) => void;
  shouldShowToast: boolean;
}

export const AppContext = React.createContext<ReachContextType>({
  cellsLoading: true,
  reachData: [],
  searchInput: '',
  setCellsLoading: () => {
    throw new Error('Method must be overridden');
  },
  setReachData: () => {
    throw new Error('Method must be overridden');
  },
  setSearchInput: () => {
    throw new Error('Method must be overridden');
  },
  setShouldShowToast: () => {
    throw new Error('Method must be overridden');
  },
  shouldShowToast: false,
});
