import React, { useContext } from 'react';
import { AppContext } from './AppContext';

export const SearchInput = () => {
  const {
    setSearchInput,
    searchInput,
    setReachData,
    setCellsLoading,
    setShouldShowToast,
  } = useContext(AppContext);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const onKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (searchInput && event.key === 'Enter') {
      try {
        setCellsLoading(true);
        const encodedUrl = encodeURI(searchInput);

        const response = await fetch(`/proxy/reach?query=${encodedUrl}`);

        const { data } = await response.json();

        if (data === undefined || data.length === 0) {
          setShouldShowToast(true);
        }
        setReachData(data);
        setCellsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className="bp3-input-group .modifier">
      <span className="bp3-icon bp3-icon-search"></span>
      <input
        className="bp3-input"
        type="search"
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder="Article or Bitly URL"
        value={searchInput}
        dir="auto"
      />
    </div>
  );
};
